<template>
    <div class="container-fluid p-0 m-0">
        <section class="container head">
            <div class="title">{{ $t('faqs.faqs') }}</div>
        </section>

        <section class="banner-img">
            <img class="img" src="https://static.vitaalumnicn.com/static/vsweb/faqs-1-1.png" alt="">
        </section>

        <Faqs cityId="0" apartmentId="0" :showSearch="true"></Faqs>

        <section class="banner-img">
            <img class="img" src="https://static.vitaalumnicn.com/static/vsweb/faqs-2-1.png" alt="">
        </section>

    </div>
</template>

<script>
import Faqs from '@/components/faqs'

export default {
    name: "faqs",
    components: {
        Faqs
    },
    data() {
        return {}
    },
    computed: {},
    methods: {}
}
</script>

<style lang="less" scoped>

.head {
    padding: 90px 45px;
    background: #fff;
    margin: 0 auto;
    font-size: #000;

    .title {
        font-size: 60px;
        font-weight: 700;
    }

    .title:before {
        margin-top: -10px;
        width: 90px;
        content: "";
        display: block;
        background-color: #e91a4c;
        position: absolute;
        height: 7px;
    }
}

.banner-img {
    background-color: #fff;
    padding: 0 45px;

    .img {
        width: 100%;
        height: auto;
    }

}


</style>
